import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from "aos";
//sections
export default {
  name: "about",
  components:{
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchContent(this.$route.path.split('/').pop())
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchContent(this.$route.path.split('/').pop())
  },
  computed:{
    ...mapGetters({
      contentList: 'pages/page'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods:{
    ...mapActions({
      fetchContent: 'pages/GET_PAGE'
    }),
  }
}